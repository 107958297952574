import { FC, ReactNode, ReactElement } from 'react';
import { Tabs, Tooltip } from 'antd';
import classnames from 'classnames';

import styles from './Tabs.module.scss';

const { TabPane } = Tabs;

export interface ITab {
  key: string;
  label: ReactElement | string;
  icon?: ReactNode;
  content: ReactNode | string;
  closable?: boolean;
  closeIcon?: ReactNode;
  render?: boolean;
  forceRender?: boolean;
  isError?: boolean;
  [key: string]: any;
}

interface ITabsProps {
  className?: string;
  activeKey?: string;
  defaultActiveKey?: string;
  onChange?: (key: string) => void;
  onEdit?: (e: any, action: "add" | "remove") => void;
  onTabClick?: (key: string) => void;
  tabs: ITab[];
  editable?: boolean;
  hideAdd?: boolean;
  tabBarExtraContent?: ReactNode;
  destroyInactiveTabPane?: boolean;
  isErrorTabs?: boolean;
}

export const ITabs: FC<ITabsProps> = ({
  activeKey,
  defaultActiveKey,
  onChange,
  onEdit,
  onTabClick,
  tabs,
  editable,
  hideAdd,
  tabBarExtraContent,
  isErrorTabs, // включает подсветку красным табов с ошибками
  className = '',
  destroyInactiveTabPane = false,
}) => {
  const renderTabs = tabs.map((tab) => {
    return { render: true, ...tab };
  });

  const items = renderTabs
        .filter((tab) => tab.render)
        .map((tab) => {
          const {content, ...rest} = tab;
          const label = tab.isError ? (
            <Tooltip title="Проверьте ошибки" placement='top'>
              <div data-error="true">{tab.label}<span style={{color: 'red'}}>{" "}*</span></div>
            </Tooltip>
          ) : (
            tab.label
          );

          return {...rest, label, children: content}
        });

  return (
    <Tabs
      onChange={onChange}
      onEdit={onEdit}
      onTabClick={onTabClick}
      activeKey={activeKey}
      defaultActiveKey={defaultActiveKey}
      className={classnames(styles.Tabs, {
        [className]: className,
        [styles.errorTabs]: isErrorTabs,
      })}
      type={editable ? 'editable-card' : 'card'}
      tabBarExtraContent={tabBarExtraContent}
      hideAdd={hideAdd}
      destroyInactiveTabPane={destroyInactiveTabPane}
      items={items}
    />
  );
};
