export enum RouteNames {
    LOGIN = '/login',
    REGISTRATION = '/registration',
    FORGET_PASSWORD = '/forget_password',
    CONTRACTORS = '/contractors',
    CONTACT = '/contact',
    STAFF = '/staff',
    MAIN = '/',
    CONTAINERS = '/containers',
    CONTAINERS_ID = '/containers/:id',
    TERMINALS = '/terminals',
    DEPOT = '/depot',
    DEAL = '/deal',
    DEAL_ID = '/deal/:id',
    NOT_FOUND = '*',
}
