import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

interface NumericInputProps {
    style: React.CSSProperties;
    placeholder: string;
    setValue: (value: number | null) => void;
    value: string;
}

const NumericInput: React.FC<NumericInputProps> = ({
                                                       value,
                                                       setValue,
                                                       style,
                                                       placeholder
                                                   }) => {
    const [valueNum, setValueNum] = useState<string>(value);

    useEffect(() => {
        setValueNum(value);
    }, [value]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            setValueNum(inputValue);
        }
    };
    const onBlur = () => {
        let val = parseFloat(valueNum);
        if (isNaN(val)) {
            setValue(null);
        } else {
            setValue(val);
        }
    };

    return (
        <Input
            allowClear
            style={style}
            value={valueNum}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            maxLength={16}
        />
    );
};

export default NumericInput;
