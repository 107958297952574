import React, { useEffect, useCallback, useMemo } from 'react';
import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Select, Typography } from "antd";
import Tabs from "../../components/ui/Tabs";
import TabDeal from "./tabs/tabDeal";
import { RootState, useStoreDispatch } from "../../store";
import { getCurrency, getPercent } from "../../store/store";
import { findCompany, findMyCompany } from "../../store/contractors";
import TabDocument from "./tabs/tabDocument";
import { generateIdDeal } from "../../store/deal";
import ModalConfirm from '../../components/ui/ModalConfirm/ModalConfirm';
import { messagesModal } from '../../constants';
import { useSelector } from 'react-redux';
import './style.scss';
import { useState } from 'react';


const { title: warningTitle, subtitle } = messagesModal.warning;
const dealNames = ['name_deal'];

interface FormDealProps {
    onClose?: () => void;
    onFinish?: () => void;
    title?: string;
}

const FormDeal = ({ onClose, onFinish, title }: FormDealProps) => {
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState(() => '1')
    const [confirmation, setConfirmation] = React.useState<boolean>(false);
    const [isErrors, setErrors] = React.useState<{deal: string[], doc: string[]}>(() => ({deal: [], doc: []}));
    const dispatch = useStoreDispatch();
    const dataCurrency = useSelector((state: RootState) => state.stores.dataCurrency);
    const dataPercent = useSelector((state: RootState) => state.stores.dataPercent);
    const getFindCompany = useCallback((searchText: string) => {
        if (searchText.length > 2) {
            dispatch(findCompany(searchText));
        }
    }, [dispatch]);

    const selectedType = (val: string | null) => {
        dispatch(generateIdDeal('П'))
    }

    const tabs = useMemo(() => [
        {
            key: '1',
            label: 'Сделка',
            forceRender: true,
            isError: !!isErrors['deal']?.length,
            content:
                <TabDeal
                    form={form}
                    onClose={() => {
                        if (onClose) {
                            onClose()
                        }
                    }
                    }
                    getFindCompany={getFindCompany}
                />
        },
        {
            key: '2',
            label: 'Документы',
            forceRender: true,
            isError: !!isErrors['doc']?.length,
            content:
                <TabDocument
                    form={form}
                    onClose={() => {
                        if (onClose) {
                            onClose()
                        }
                    }
                    }
                />
        },
    ], [isErrors.deal?.length, isErrors.doc?.length, form, getFindCompany, onClose]);


    const onSubmit = async (values: any) => {
        // values.company = company;
        // values.my_company = companyMy;
        // values.responsible = arResponsible.map(item => item.id);
        console.log(values);
        return;
    };
    const onUpdate = async (changedValues: [], allValues: []) => {
        // values.company = company;
        // values.my_company = companyMy;
        // values.responsible = arResponsible.map(item => item.id);
        console.log([changedValues]);
        return;
    };
    const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
        // values.company = company;
        // values.my_company = companyMy;
        // values.responsible = arResponsible.map(item => item.id);
        console.log(values, errorFields, outOfDate);
        if (!outOfDate) {
            const result = {...isErrors};
            errorFields.forEach(({name}: {name: string[]}) => {
              const [title] = name;
              if (dealNames.includes(title)) {
                result.deal.push(title);
              }
            });
            setErrors((prev) => ({...prev, ...result}))
        }

        return;
    };

    useEffect(() => {
        dispatch(getCurrency());
        dispatch(findMyCompany());
        dispatch(getPercent());
    }, [dispatch]);
    useEffect(() => {
        form.resetFields()
        // console.log(form.Errors())
    }, []);
    const getErrors = () => {
        console.log(form.getFieldsError());
        // console.log(form.Errors())
    };

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        setErrors({deal: [], doc: []});
        form.resetFields()
    }

    return (
        <Form
            className='tabs-form'
            autoComplete="off"
            noValidate
            layout="vertical"
            form={form}
            initialValues={{
                currency: dataCurrency[0]?.value || null,
                nds: dataPercent[0]?.value || null,
                type_deal: 'Закуп',
                comment: null,
                name_deal: null,
                id_deal: null,
                bet: null,
                company: '',
                my_company: '',
                account_num: null,
            }}
            scrollToFirstError
            onFinish={onSubmit}
            onFinishFailed={onFinishError}
            onValuesChange={onUpdate}
        >

            <CloseOutlined className='close' onClick={handleClose} />
            <Flex justify='space-between' align='center'>
                <Flex gap={10} align='center' style={{ marginTop: 15 }}>
                    <Typography.Text className='label-card-20'>{title}</Typography.Text>
                </Flex>
                <Col span={8}>
                    <Flex vertical>
                        <Typography.Text className='text-card-12'>Тип сделки</Typography.Text>

                        <Form.Item name={'type_deal'}>
                            <Select placeholder="Выберите тип сделки" onChange={selectedType} options={[
                                { value: 'Доставка КТК', label: 'Доставка КТК' },
                                { value: 'Доставка груза', label: 'Доставка груза' },
                                { value: 'Закуп', label: 'Закуп' },
                                { value: 'Передислокация', label: 'Передислокация' },
                                { value: 'Внутрянка', label: 'Внутрянка' },
                                { value: 'Продление', label: 'Продление' },
                                { value: 'Эвакуация', label: 'Эвакуация' },
                                { value: 'Забрал клиент', label: 'Забрал клиент' },
                            ]} />
                        </Form.Item>


                    </Flex>
                </Col>
            </Flex>

            <Tabs activeKey={activeKey} tabs={tabs} isErrorTabs onChange={setActiveKey} />
            <ModalConfirm title={warningTitle} subtitle={subtitle} isOpen={confirmation}
                closeModal={() => setConfirmation(false)}
                actionAfterConsent={handleClose} />
            <Form.Item>
                <Flex gap={8} style={{ margin: '32px' }}>
                    <Button type={'primary'} style={{ maxWidth: '290px', minWidth: '290px', }} htmlType="submit" onClick={getErrors}>Добавить</Button>
                    <Button onClick={() => setConfirmation(true)} type={'text'}
                        style={{ width: '30%', color: '#E14453' }}>Отменить</Button>
                </Flex>
            </Form.Item>

        </Form>
    );
};

export default FormDeal;
