import React, { useState } from 'react';
import { AutoComplete, Avatar, Dropdown, Flex, Form, FormInstance, FormListFieldData, Input, MenuProps, message, Select, Typography } from "antd";
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, UserOutlined } from "@ant-design/icons";
import Icon from '@ant-design/icons/lib/components/Icon';
import UploaderDocument from "../../../components/ui/UploaderDocument";
import TooltipButton from '../../../components/ui/TooltipButton/TooltipButton';
import Scroll from './Scroll';
import { useStoreDispatch } from "../../../store";
import { findContainers } from "../../../store/containers";
import { moreIcon } from '../../../assets/image/svg';
import ModalConfirm from '../../../components/ui/ModalConfirm/ModalConfirm';
import { Utils } from "../../../utils";
import '../style.scss';
import { messagesModal } from '../../../constants';

const { title, subtitle } = messagesModal.delete;


interface ITabDocument {
    onClose: () => void;
    form: FormInstance<any>,
}
interface IValues {
    [key: string]: any;
}
interface IData {
    id?: number;
    num_container: string;
    status: string | null;
    bet?: number | string | null;
    price?: number | string | null;
    type?: string | number;
    type_container?: string | number;
    isEdit?: boolean;
}

const TabDocument: React.FC<ITabDocument> = ({ onClose, form }) => {
    const deals = Form.useWatch('deals', { form, preserve: true });
    const [options, setOptions] = React.useState<IData[] | []>([]);
    const [confirmation, setConfirmation] = React.useState<boolean>(false);
    const [values, setValues] = React.useState<IValues>({});
    const dispatch = useStoreDispatch();
    const [arResponsible, setArResponsible] = useState<any[]>([{
        avatar: Utils.getUserInfo().avatar,
        name: Utils.getUserInfo().fio,
        id: Utils.getUserInfo().id
    }]);
    const [value, setValue] = useState<string>('');
    const [data, setData] = useState<IData[]>([]);

    const showModalResponsible = () => {
        // Logic to show modal
    };

    const editContainer = (edit: (defaultValue?: any, insertIndex?: number) => void, index: number) => {

        const container = { ...deals[index] };
        setValues((prev) => ({ ...prev, [index]: { ...prev[index], price: container?.price, status: container?.status } }));
        container.isEdit = !container.isEdit;
        const result = [...deals];
        result[index] = container;
        form.setFieldValue('deals', [...result]);

    };

    const getItems = (remove: () => void, edit: (defaultValue?: any, insertIndex?: number) => void, index: number): MenuProps['items'] => [
        {
            key: 1, label: <TooltipButton propsTooltip={{ placement: 'left', title: 'Редактировать' }} propsButton={{ onClick: () => editContainer(edit, index), icon: <EditOutlined />, shape: "circle" }} />,
        },
        {
            key: 2, label: <TooltipButton propsTooltip={{ placement: 'left', title: 'Удалить' }} propsButton={{ onClick: () => remove(), icon: <DeleteOutlined />, shape: "circle" }} />,
        },
    ];

    const addRow = (val: string, addForm: (defaultValue?: any, insertIndex?: number) => void, key: string) => {
        const container: IData | undefined = options?.find((opt: IData) => opt?.num_container === val);
        console.dir(deals);
        const isUnique = !deals?.some((item: any) => item?.num_container === val);
        if (isUnique && container) {
            const deal = {
                num_container: val,
                type: container?.type_container,
                status: null,
                bet: container?.price ?? null,
                price: '',
                isEdit: false
            };
            setValues((prev) => ({ ...prev, [val]: {} }));
            setValue('');
            addForm({ ...deal }, 0);
        } else {
            message.warning('Этот контейнер уже добавлен!');
            setValue('');
        }
    };

    const handleContainer = (data: any) => {
        setOptions([]);
        setValue(data);
    }

    const getFindContainer = (text: string) => {
        if (text?.length >= 3) {
            dispatch(findContainers(text))
                .then((res) => setOptions(res.payload))
                .catch((err) => console.log("ошибка запроса", err));
        }
    }

    const handleUpdatePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (!reg.test(input.value)) {
            input.value = input.value.slice(0, input.value?.length - 1);
        }
    };

    const submitContainer = (index: number) => {

        const container = { ...form.getFieldValue('deals')?.[index] };
        setValues((prev) => ({ ...prev, [index]: { ...prev[index], price: container?.price, status: container?.status } }));
        const fields = form.getFieldsValue()
        const { deals } = fields
        Object.assign(deals[index], { ...container, isEdit: false })
        form.setFieldsValue({ deals })
    }
    const closeEditContainer = (index: number) => {

        const container = { ...values[index] };
        setValues((prev) => ({ ...prev, [index]: { ...prev[index], price: container?.price, status: container?.status } }));
        const fields = form.getFieldsValue()
        const { deals } = fields
        Object.assign(deals[index], { ...values[index], isEdit: false })
        form.setFieldsValue({ deals })
    }


    return (
        <Flex gap={30} vertical>
            <UploaderDocument />
            <div className="overlap-group-deal">
                <div className="table">
                    <div className="text-container">
                        <div className={'header__deal'}>
                            <div className="table-cell__deal_1 label-card-16">№ КТК</div>
                            <div className="table-cell__deal label-card-16">Тип</div>
                            <div className="table-cell__deal label-card-16">Статус</div>
                            <div className="table-cell__deal label-card-16">Входящая ставка</div>
                            <div className="table-cell__deal label-card-16">Стоимость</div>
                            <Flex align="center">
                                <div className="label-card-16">
                                    <Dropdown menu={{ items: [] }} placement='bottomLeft' trigger={['click']}>
                                        <a className='' onClick={(e) => e.preventDefault()}>
                                            <Icon component={moreIcon} />
                                        </a>
                                    </Dropdown>
                                </div>
                            </Flex>
                        </div>
                        <Scroll className="scroll-instance_deal" hovered={false}
                            knobClassName="design-component-instance-deal-node">
                            <Form.List initialValue={[]} name="deals">
                                {(fields: FormListFieldData[], operation, meta: { errors: any }) => (
                                    <div className="table-body">
                                        <AutoComplete
                                            value={value}
                                            placeholder={'Введите № КТК'}
                                            style={{ width: '26%', minWidth: '110px' }}
                                            options={options?.map((opt: IData) => ({ id: opt?.id, value: opt?.num_container }))}
                                            onSelect={(val) => addRow(val, operation?.add, `${fields.length}`)}
                                            onChange={handleContainer}
                                            onSearch={(text: string) => getFindContainer(text)}
                                            maxLength={11}
                                            allowClear
                                        />

                                        {fields.map(({ ...field }) => (
                                            <div key={field.key} className="table-row">
                                                <div className="table-cell__deal text-card-14">
                                                    {form.getFieldValue('deals')?.[field.name]?.['num_container']}
                                                </div>
                                                <div className="table-cell__deal text-card-14">
                                                    {form.getFieldValue('deals')?.[field.name]?.['type']}
                                                </div>
                                                <div className="table-cell__deal text-card-14">
                                                    {form.getFieldValue('deals')?.[field.name]?.['isEdit'] ? <Form.Item
                                                        name={[field.name, 'status']}
                                                    >
                                                        {
                                                            <Select
                                                                placeholder="Добавьте статус"
                                                                style={{ width: '100%' }}
                                                                options={[
                                                                    { value: 'В пути', label: 'В пути' },
                                                                    { value: 'Вышел из депо', label: 'Вышел из депо' },
                                                                ]}
                                                            />

                                                        }
                                                    </Form.Item> : <span>{form.getFieldValue('deals')?.[field.name]?.['status'] || 'Добавьте статус'}</span>}


                                                </div>
                                                <div className="table-cell__deal text-card-14">

                                                    <span>{form.getFieldValue('deals')?.[field.name]?.['bet'] ?? ""}</span>
                                                </div>
                                                <div className="table-cell__deal text-card-14">
                                                    {form.getFieldValue('deals')?.[field.name]?.['isEdit'] ? <Form.Item
                                                            name={[field.name, 'price']}
                                                        >

                                                            <Input
                                                                placeholder="Добавьте цену"
                                                                allowClear
                                                                onInput={handleUpdatePrice}
                                                            />

                                                        </Form.Item> : <span>{form.getFieldValue('deals')?.[field.name]?.['price'] || 'Добавьте цену'}</span>}


                                                </div>
                                                <Flex className={`table-row__menu ${form.getFieldValue('deals')?.[field.name]?.['isEdit'] ? 'edit' : ''}`} align="center">
                                                    <div className="text-card-14">
                                                        {form.getFieldValue('deals')?.[field.name]?.['isEdit'] ?
                                                            (
                                                                <Flex vertical gap={5}>
                                                                    <TooltipButton propsTooltip={{ title: 'Сохранить' }} propsButton={{ className: 'table-row__button', shape: 'circle', icon: <CheckOutlined />, onClick: () => submitContainer(field.name) }} />
                                                                    <TooltipButton propsTooltip={{ title: 'Закрыть' }} propsButton={{ className: 'table-row__button', shape: 'circle', icon: <CloseOutlined />, onClick: () => closeEditContainer(field.name) }} />

                                                                </Flex>

                                                            ) : (
                                                                <Dropdown menu={{ items: getItems(() => setConfirmation(true), operation.add, field.name), style: { background: 'transparent', boxShadow: 'none' } }} placement='bottom' trigger={['click']}>
                                                                    <a className='' onClick={(e) => e.preventDefault()}>
                                                                        <Icon component={moreIcon} />
                                                                    </a>
                                                                </Dropdown>)}
                                                                <ModalConfirm title={title} subtitle={subtitle} isOpen={confirmation} closeModal={() => setConfirmation(false)} actionAfterConsent={() => operation.remove(field.name)}/>
                                                    </div>
                                                </Flex>
                                            </div>
                                        ))}

                                    </div>
                                )}
                            </Form.List>
                        </Scroll>
                    </div>
                </div>
            </div>
            <div className="footer">
                <Flex gap={6} vertical>
                    <Typography.Title level={4} style={{ marginBottom: 0 }}>Дополнительно</Typography.Title>
                    <Typography.Text style={{ marginTop: 0 }}>Ответственный</Typography.Text>
                    <Flex justify={'space-between'} align={'center'} style={{ paddingRight: 7 }}>
                        <Flex vertical gap={6}>
                            {arResponsible.map((item, index) => (
                                <Flex gap={6} key={index} align={"center"}>
                                    <Avatar size={28} src={item.avatar !== null && item.avatar}
                                        icon={<UserOutlined />} />
                                    <Typography.Text style={{ marginTop: 0, color: '#05F' }}>
                                        {item.name}
                                    </Typography.Text>
                                </Flex>
                            ))}
                        </Flex>
                        <Typography.Text
                            style={{ marginTop: 0, color: '#05F', cursor: "pointer" }}
                            onClick={showModalResponsible}
                        >
                            Добавить ответственного
                        </Typography.Text>
                    </Flex>
                </Flex>
            </div>
        </Flex>
    );
};

export default TabDocument;
