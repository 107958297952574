import React, { ReactElement, useEffect, useState } from 'react';

import axios from 'axios';
import { Flex, GetProp, Image, message, Upload, UploadProps } from 'antd';

import { UploadFile } from 'antd/lib/upload';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import FullScreen from '../FullScreen/FullScreen';
import { messagesModal } from '../../../constants';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import UploaderDocumentItem from './UploaderDocsItem/UploaderDocsItem';
import {
  checkFileTypes,
  downloadContent,
  fileTypes,
} from '../../../helpers/input-helpers';
import style from './UploaderDocs.module.scss';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const URL: any = process.env.REACT_APP_API_URL;

interface IUploaderDocs {
  ids?: any[];
  type?: string;
  setDataId?: (value: number[]) => void;
  onChange?: (value: any) => void;
  accept?: string;
  name?: string;
  value?: any[];
  urlUpload?: string;
  urlDownLoad?: string;
  urlDelete?: string;
  isRemoved?: boolean;
  isView?: boolean;
  isOpen?: boolean;
}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const UploaderDocs: React.FC<IUploaderDocs> = ({
  name,
  ids,
  type,
  setDataId,
  onChange,
  accept = '*',
  urlUpload = '/uploadFiles',
  urlDownLoad = '/downloadFiles',
  urlDelete = '/deleteFiles',
  isRemoved = false,
  isView = false,
  isOpen = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [newFileListIds, setNewFileListIds] = useState<number[]>([]);
  const [fileListIds, setFileListIds] = useState<{ id: number; uid: string }[]>(
    [],
  );
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [deleteFile, setDeleteFile] = useState<UploadFile | null>(null);
  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleUpload: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
    onError,
    onProgress,
  }: any) => {
    try {
      if (!checkFileTypes(accept, file?.type ?? '')) {
        return Promise.reject(new Error('ошибка типа файла'));
      }
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64 = (await reader.result) as Buffer;
        axios
          .post(
            `${URL}${urlUpload}`,
            { file: base64, file_pdf: '', name: file.name, type: file.type },
            config,
          )
          .then((res) => {
            if (setDataId) {
              setDataId([...fileListIds.map(({ id }) => id), res.data]);
            }
            setFileListIds((prev) => [
              ...prev,
              { id: res.data, uid: file.uid },
            ]);
            setNewFileListIds((prev) => [...prev, res.data]);
            onSuccess(file);
            message.success('Файл сохранен');
          })
          .catch((error) => {
            onError(error);
          });
      };
      reader.onerror = (error) => {
        message.error('Ошибка при конвертации в base64');
        onError(error);
      };
    } catch (error) {
      message.error('Ошибка при загрузке файла');
      onError(error);
    }
  };

  const handleChange: UploadProps['onChange'] = ({
    fileList: newFileList,
    file,
  }) => {
    setFileList(newFileList);

    if (file.status === 'uploading') {
      setLoading(true);
      if (!checkFileTypes(accept, file?.type ?? '')) {
        message.error('Недопустимый тип файла');
        setLoading(false);
        return;
      }
      if (fileList.find(({ uid }) => uid === file.uid)) {
        message.error('Такой файл уже есть');
        setLoading(false);
        return;
      }
    }

    const result = newFileList.filter((file) => !file.error);

    if (file.status === 'done' || file.error) {
      setNewFileListIds((prev) => [...prev]);
      setLoading(false);
      setFileList(result);
    }
  };

  const handlePreview: UploadProps['onPreview'] = async (file: any) => {
    if (!file?.url) {
      getBase64(file.originFileObj as FileType, (url) => {
        // HACK: для открытия картинок
        // if (checkFileTypes(fileTypes.jpeg, file?.type ?? '')) {
        //   setImageUrl(url);
        //   setPreviewVisible(true);
        //   return;
        // }
        downloadContent({
          content: '',
          type: file?.type,
          fileName: file?.name,
          url,
        });
      });
      return;
    }
    // HACK: для открытия картинок
    // if (checkFileTypes(fileTypes.jpeg, file?.type ?? '')) {
    //   setImageUrl(file.url);
    //   setPreviewVisible(true);
    //   return;
    // }

    downloadContent({
      content: '',
      type: file?.type,
      fileName: file?.name,
      url: file.url,
    });
  };

  const openConfirmDeleteFile = (file: UploadFile) => {
    setDeleteFile(file);
    setIsConfirm(true);
  };

  const handleRemove = () => {
    const filesIds = fileListIds.filter(({ uid }) => uid !== deleteFile?.uid);
    const resultId = filesIds.map(({ id }) => id);
    setFileListIds((prev) => prev.filter(({ uid }) => uid !== deleteFile?.uid));
    if (onChange) {
      onChange(resultId);
    }
    if (setDataId) {
      setDataId(resultId);
    }

    setFileList((prev) => prev.filter(({ uid }) => uid !== deleteFile?.uid));
    setDeleteFile(null);
  };

  const onCloseModal = () => setPreviewVisible(false);

  useEffect(() => {
    setFileList([]);
    if (setDataId) {
      setDataId([]);
    }
    if (ids?.length) {
      const fetchFiles = async () => {
        const getArrayFiles = (data: UploadFile[]) =>
          data.map((item: any) => ({
            id: item.id,
            uid: item.id,
            name: item.name,
            type: item.type,
            status: 'done' as UploadFileStatus,
            url: item.file,
          }));
        try {
          const { data } = await axios.post(
            `${URL}${urlDownLoad}`,
            ids,
            config,
          );
          const currentIds = data.map((file: any) => ({
            id: file.id,
            uid: file.id,
          }));
          const listIds = currentIds.map((file: any) => file.id);
          setFileList(getArrayFiles(data));
          setFileListIds(currentIds);
          if (setDataId) {
            setDataId(listIds);
          }
        } catch (error) {
          message.error('Не удалось загрузить файл');
        }
      };
      fetchFiles();
    }
  }, []);

  useEffect(() => {
    return () => {
      if (isRemoved && newFileListIds?.length) {
        axios
          .post(
            `${URL}${urlDelete}`,
            { ids: newFileListIds, isDelete: true },
            config,
          )
          .then(() => message.success('успешно'))
          .catch((err) => console.error('ошибка удаления'));
      }
    };
  }, [isRemoved]);

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
    </button>
  );

  return (
    <>
      <Upload
        className={style['container']}
        name={name}
        accept={accept}
        customRequest={handleUpload}
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        itemRender={(
          originNode: ReactElement,
          file: UploadFile,
          fileList: any[],
          actions: {
            download: (file: UploadFile) => void;
            preview: any;
            remove: any;
          },
        ) => (
          <UploaderDocumentItem
            isView={isView}
            file={file}
            handlePreview={handlePreview}
            openConfirmDeleteFile={openConfirmDeleteFile}
          />
        )}
      >
        {isView ? null : uploadButton}
      </Upload>
      <ModalConfirm
        title={messagesModal.delete.title}
        subtitle={messagesModal.delete.subtitle}
        isOpen={isConfirm}
        closeModal={() => setIsConfirm(false)}
        actionAfterConsent={handleRemove}
      />
      <FullScreen isOpen={previewVisible} onClose={onCloseModal}>
        <Flex justify="center" style={{ width: '100%', height: '100%' }}>
          <Image src={imageUrl} />
        </Flex>
      </FullScreen>
    </>
  );
};
