import {Button, Empty, Flex} from "antd";
import {useHistory} from "react-router-dom";

const Page404 = () => {
    const history = useHistory()
    const reload = () =>{
        history.push("/");
    }
    return (
        <Flex justify={'center'} align={'center'} style={{height: '100vh'}}>
            <Empty
                description={
                    <span>
                        Похоже нет интернета!
                    </span>
                }>
                <Button type="primary" onClick={reload}>Перегрузить</Button>
            </Empty>
        </Flex>
    );
};

export default Page404;