import React, { createContext, FC, useState } from 'react';
import { Flex, Form, Input, notification } from "antd";
//import {useTypedSelector} from "../hooks/useTypedSelector";
//import {useActions} from "../hooks/useActions";
import { NotificationPlacement } from "antd/es/notification/interface";
import { useHistory } from "react-router-dom";
import { EmailInputIcon, EyeIcon, LockIcon, openEyeIcon } from '../../assets/image/svg';
import { RouteNames } from "../../router/names";
import { useStoreDispatch } from "../../store";
import { Login } from "../../store/users";
import Button from '../ui/Button/Button';
import s from './LoginForm.module.scss';
import { Checkbox } from '../ui/Checkbox/Checkbox';
import Icon from '@ant-design/icons/lib/components/Icon';

interface IClickRegistration {
    clickRegistration: () => void
}

const Context = createContext({ name: 'Default' });
const LoginForm: FC<IClickRegistration> = ({clickRegistration}) => {
    const router = useHistory()
    const dispatch = useStoreDispatch()
    const [api, contextHolder] = notification.useNotification();
    const [choice, setChoice] = useState<boolean>(false)

    const onFinish = async (data: any) => {
        const result = await dispatch(Login(data));
        const jRes = JSON.parse(result.payload)
        if (jRes.success === 0){
            openNotification('topRight',jRes.message);
        }else {
            router.push(RouteNames.MAIN)
        }
    }
    const openNotification = (placement: NotificationPlacement, message: string) => {
        api.error({
            message: `Ошибка`,
            description: <Context.Consumer>{({ name }) => message}</Context.Consumer>,
            placement,
        });
    };

    const clickForgetPassword = () => {
        router.push(RouteNames.FORGET_PASSWORD)
    }


    return (
        <div className={s.container}>
            {contextHolder}
            <Flex justify='center' vertical>
                <h1 style={{marginBottom: 30, textAlign: 'center', fontFamily: 'Manrope', fontSize: 32, fontWeight: 700 }}>Добро пожаловать!</h1>
                <Form
                    name="normal_login"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        rules={[{required: true, message: 'Пожалуйста, введите свое имя пользователя!'}]}>
                        <Input
                            prefix={<EmailInputIcon/>}
                            placeholder="Email"
                            style={{marginBottom: 10}}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: 'Пожалуйста, введите свой пароль!'}]}
                    >
                        <Input.Password
                            prefix={<Icon component={LockIcon}/>}
                            type="password"
                            placeholder="Пароль"
                            iconRender={(visible) => (visible ?  <Icon component={openEyeIcon}/> : <Icon component={EyeIcon}/>)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Flex vertical align="end">
                            <p className={s.color} onClick={clickForgetPassword} >
                                Забыли пароль
                            </p>
                            </Flex>
                            <Flex gap={'5px'}>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox isChoice={choice} onChange={setChoice} style={{}}/>
                                </Form.Item>
                                <p>Запомнить</p>
                            </Flex>

                    </Form.Item>
                    <Form.Item>
                        <Flex align={'center'} vertical gap={'15px'}>
                            <Button type='primary' style={{width: '100%'}}>
                                Вход
                            </Button>
                            <p style={{color: '#0055FF'}} onClick={clickRegistration} className={"ahref"}>или зарегистрируйтесь</p>
                        </Flex>
                    </Form.Item>
                </Form>
            </Flex>
        </div>
    );
};

export default LoginForm;
