import { Flex, Tabs, TabsProps, Typography } from "antd";
import { useEffect, useState } from 'react';
import Contacts from "../pages/contacts";
import Contractors from "../pages/contractors";
import { Utils } from "../utils";
import Layouts from "./Layouts";
import '../assets/css/srm-container.scss'


const { Title } = Typography;
const CrmContainers = () => {
    const [activeKey, setActiveKey] = useState("3");
    const [name, setName] = useState('');

    useEffect(() => {
        const localUser = async () => {
            if (Utils.getStorage('_in') !== null) {
                const user = await Utils.decrypt(Utils.getStorage('_in'))
                setName(user.name === null ? '' : user.name)
            }
        }
        localUser();
    },[]);


    const items: TabsProps['items'] = [
        { key: '1', label: 'Лиды', children: "Лиды" },
        { key: '2', label: 'Сделки', children: "Сделки" },
        { key: '3', label: 'Компании', children: <Contractors/> },
        { key: '4', label: 'Контакты', children: <Contacts/> },
        { key: '5', label: 'Интеграции', children: "Интеграции" },
    ];

    return (
        <Layouts>
            <Flex style={{width:'100%'}} vertical>
                <Title level={2} style={{padding: '20px 18px'}}>Доброе утро {name ?? '' }!</Title>
                    <Tabs
                        defaultActiveKey={activeKey}
                        activeKey={activeKey}
                        onChange={setActiveKey}
                        items={items}
                        style={{width:'100%'}}
                        indicator={{ size: (origin: any) => origin - 100, align: "center" }}
                    />
            </Flex>
        </Layouts>
    );
};

export default CrmContainers;
