import { Drawer } from 'antd';
import '../assets/css/drawer.scss'
import { useRef } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useEffect } from 'react';
import TooltipButton from './ui/TooltipButton/TooltipButton';

const styleArrow: React.CSSProperties = {
    position: 'absolute',
    left: '-40px',
    top: '50%',
    cursor: 'pointer'
};

const DrawerComponent = (props: any) => {
    const ref = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState('auto');
    useEffect(() => {
        if (props.open) {
            setWidth('auto');
        }
    }, [props.open]);
    const handlerUpSize = (evt: any) => {
            const widthWindow = window.innerWidth;
            if (ref?.current) {
                const width = ref?.current?.getBoundingClientRect()?.width;
                if (widthWindow - width > 200) {
                    ref.current.style.animation = 'width 1s';
                    setWidth(`${width + 50}px `);
                }
            }
    };
    return (

        <Drawer
            width={'auto'}
            placement="right"
            closable={false}
            forceRender={false}
            open={props.open}
        >
            <div ref={ref} style={{ minWidth: 'calc(45vw - 250px)', width }}>
                <TooltipButton propsTooltip={{title: 'Расширить окно'}} propsButton={{ style: styleArrow, shape: 'circle', icon: <ArrowLeftOutlined />, onClick: handlerUpSize}} />
                {props.form}
            </div>
        </Drawer>

    );
};

export default DrawerComponent;
