import React, {useRef, useState} from 'react';
import Container from "../../components/Container";
import {AutoComplete, Button, Flex, Input, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {columns} from "./columns";
import {useTranslation} from "react-i18next";
import {Table} from "../../components/ui/Table/Table";
import {Utils} from "../../utils";
import DrawerComponent from "../../components/DrawerComponent";
import FormDeal from "./formDeal";

const Index = () => {
    const {t} = useTranslation();
    const [page, setPage] = useState<number>(20);
    const [total, setTotal] = useState<number>(0);
    const [data, setData] = useState<any[]>(Utils.defaultTable);
    const [loading, setLoading] = useState<boolean>(false);
    const [openDrawerDeal, setOpenDrawerDeal] = useState<boolean>(false);
    const [typeForm, setTypeForm] = useState<string>('');

    const handleShowDrawerDeal = () => {
        setTypeForm('create')
        setOpenDrawerDeal(true);
    }

    const onCloseFormDeal = () => {
        setOpenDrawerDeal(false);
    }

    const onFinish = () => {

    }

    return (
        <Container>
            <Flex gap={20} justify={'space-between'} align={'flex-start'} style={{width: '100%'}}
                  className={'container'}>
                <Flex align={"center"} gap={10}>
                    <div className={'drawer-title'}>{t('Deal')}</div>
                </Flex>
                <Flex gap={16}>
                    <Button icon={<PlusOutlined/>} style={{background: '#05F', color: '#fff'}}
                            onClick={handleShowDrawerDeal}/>
                    <AutoComplete
                        allowClear
                        style={{width: 300}}
                        options={[]}
                        popupMatchSelectWidth={false}>
                        <Input placeholder="Введите текст"/>
                    </AutoComplete>
                </Flex>
            </Flex>
            <Space direction="vertical" size="middle" style={{width: "100%"}}>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    height={'calc(-162px + 100vh)'}
                    pagination={{pageSize: page, total: Number(total), showSizeChanger: true}}/>
            </Space>
            <DrawerComponent
                open={openDrawerDeal}
                form={
                    <FormDeal
                        title={typeForm === 'create' ? 'Создать сделку':'Редактировать сделку'}
                        onFinish={onFinish}
                        onClose={onCloseFormDeal}
                    />
                }
            />
        </Container>
    );
};

export default Index;
