const messagesModal = {
  warning: {
    title: 'Предупреждение',
    subtitle: 'Вы уверены, что хотите отменить?',
  },
  delete: {
    title: 'Предупреждение',
    subtitle: 'Вы уверены, что хотите удалить?',
  }
};


export {messagesModal};